.mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.mobile-overlay h1 {
  font-size: 24px;
  color: #fff;
  text-align: center;
  padding: 20px;
  max-width: 80%;
}

@media only screen and (min-width: 768px) {
  /* Hide the overlay on screens wider than 768px (desktop) */
  .mobile-overlay {
    display: none;
  }
}
